
import { Component, Vue } from "vue-property-decorator";
import SendFeriaTicketForm from "@/components/forms/SendFeriaTicketForm.vue";
import FormCard from "@/components/FormCard.vue";

@Component({
  components: {
    SendFeriaTicketForm,
    FormCard
  }
})
export default class SendRequest extends Vue {
  private mounted() {
    window.scrollTo(0, 0);
  }
}
